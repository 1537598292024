<script setup lang="ts">
  import NameInput from '~/UI/Inputs/NameInput.vue';
  import PhoneInput from '~/UI/Inputs/PhoneInput.vue';
  import Checkbox from '~/UI/Inputs/Checkbox.vue';
  import EcButton from '~/UI/Buttons/EcButton.vue';
  import YandexCaptchaWrapper from '~/UI/Yandex/CaptchaWrapper.vue';
  const bookingStore = useBookingStore();

  const { showHelpModal } = storeToRefs(bookingStore);
  const captchaWrapper = ref<HTMLElement | null>(null);
  const error = ref<string>('');

  const submit = () => {
    bookingStore.loading = true;
    error.value = '';
    if (!bookingStore.validate()) {
      setTimeout(() => {
        bookingStore.loading = false;
      }, 100);
      return;
    }
    if (captchaWrapper.value) {
      captchaWrapper.value?.captchaSubmit();
    } else {
      setTimeout(() => {
        error.value = 'Что-то пошло не так. Попробуйте обновить страницу и отправить форму ещё раз.';
        bookingStore.loading = false;
      }, 300);
    }
  };
  const callbackToPass = () => {
    bookingStore.bookShort();
  };
</script>

<template>
  <div class="title-block">
    <span class="bold-text">Давайте поможем</span>
    <div class="close-container" @click="showHelpModal = false">
      <IconClose class="icon-close" />
    </div>
  </div>
  <div class="second-text">
    Напишите, как вас зовут и по какому номеру можно связаться — позвоним и поможем вам подобрать врача
  </div>
  <form class="form-block">
    <NameInput />
    <PhoneInput />
    <Checkbox />
    <ClientOnly>
      <YandexCaptchaWrapper
        ref="captchaWrapper"
        :callback="callbackToPass"
      />
    </ClientOnly>
    <div v-if="error" class="input-error-message">
      {{ error }}
    </div>
    <div class="booking__dialog__item button margin-button">
      <EcButton
        :loading="bookingStore.loading"
        :disable="bookingStore.loading"
        class="primary full-width shadow-button services-button-container pointer select-doctor-button"
        @click="submit"
      >
        <span>Жду звонка</span>
      </EcButton>
    </div>
    <!-- <BookingViewsAgreement /> -->
  </form>
  <PatientHelpFormModalFormContacts />
</template>

<style scoped lang="scss">
.title-block {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding-top: 7px;
  padding-bottom: 12px;
}
.bold-text {
  color: #171E36;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  @media (max-width: 1023px) {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
  }
}
.close-container {
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: #F2F3F6;
  cursor: pointer;
}
.icon-close {
  font-size: 20px;
  margin: 0;
  color: #262633;
}
.second-text {
  color: #262633;
  font-feature-settings: 'liga' off;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.form-block {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.input-container {
  height: 56px;
  background-color: #F2F3F6;
  padding: 16px 12px 0px 12px;
  border-radius: 15px;
  margin-bottom: 1rem;
}
.input {
  width: 100%;
  padding: 0;
  border: none;
  color: #878FA2;
  font-feature-settings: 'liga' off;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  background-color: transparent;
  &:focus {
    outline: none;
  }
}
.margin-button {
  margin-top: -10px;
}
</style>
